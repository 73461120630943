"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HandSkeleton = exports.Handedness = exports.convertPoseArrayToWorldFeatures = exports.featureIndexToKey = exports.fromPoseToWorldSpace = exports.getFeatureKey = exports.test = exports.FeatureLength = exports.FeatureNames = exports.FingerNames = void 0;
var THREE = require("three");
var three_1 = require("three");
exports.FingerNames = ['thumb', 'indexFinger', 'middleFinger', 'ringFinger', 'pinky'];
exports.FeatureNames = ['palmBase'].concat(exports.FingerNames);
exports.FeatureLength = {
    'palmBase': 1,
    'thumb': 4,
    'indexFinger': 4,
    'middleFinger': 4,
    'fingFinger': 4,
    'pinky': 4
};
function getZAxis(xAxis, yAxis, result) {
    if (result === void 0) { result = new THREE.Vector3(); }
    return result.copy(xAxis).cross(yAxis).normalize();
}
function getXAxis(yAxis, zAxis, result) {
    if (result === void 0) { result = new THREE.Vector3(); }
    return result.copy(yAxis).cross(zAxis).normalize();
}
function test() {
    var xAxis = new THREE.Vector3(1, 0, 0);
    var yAxis = new THREE.Vector3(0, 1, 0);
    var zAxis = new THREE.Vector3(0, 0, 1);
    if (!getZAxis(xAxis, yAxis).equals(zAxis))
        throw new Error("bad zAxis");
    if (!getXAxis(yAxis, zAxis).equals(xAxis))
        throw new Error("bad xAxis");
}
exports.test = test;
function getFeatureKey(featureName, jointIndex) {
    if (jointIndex === void 0) { jointIndex = undefined; }
    var featureKey = featureName;
    if (jointIndex !== undefined) {
        featureKey += jointIndex;
    }
    return featureKey;
}
exports.getFeatureKey = getFeatureKey;
function fromPoseToWorldSpace(posePosition, viewWidth, viewHeight) {
    return new THREE.Vector3(-(posePosition.x - 1 / 2), -(posePosition.y - 1 / 2), posePosition.z * 0.5);
}
exports.fromPoseToWorldSpace = fromPoseToWorldSpace;
exports.featureIndexToKey = {
    0: 'palmBase',
    1: 'thumb0',
    2: 'thumb1',
    3: 'thumb2',
    4: 'thumb3',
    5: 'indexFinger0',
    6: 'indexFinger1',
    7: 'indexFinger2',
    8: 'indexFinger3',
    9: 'middleFinger0',
    10: 'middleFinger1',
    11: 'middleFinger2',
    12: 'middleFinger3',
    13: 'ringFinger0',
    14: 'ringFinger1',
    15: 'ringFinger2',
    16: 'ringFinger3',
    17: 'pinky0',
    18: 'pinky1',
    19: 'pinky2',
    20: 'pinky3'
};
function convertPoseArrayToWorldFeatures(array, viewWidth, viewHeight) {
    var worldFeatures = {};
    array.forEach(function (value, index) {
        var featureKey = exports.featureIndexToKey[index];
        worldFeatures[featureKey] = fromPoseToWorldSpace(value, viewWidth, viewHeight);
    });
    return worldFeatures;
}
exports.convertPoseArrayToWorldFeatures = convertPoseArrayToWorldFeatures;
var Handedness;
(function (Handedness) {
    Handedness[Handedness["Left"] = 0] = "Left";
    Handedness[Handedness["Right"] = 1] = "Right";
})(Handedness = exports.Handedness || (exports.Handedness = {}));
;
function getHandednessFromLabel(handednessLabel) {
    switch (handednessLabel) {
        case 'Right': return Handedness.Right;
        case 'Left': return Handedness.Left;
        default: throw new Error("Bad label ".concat(handednessLabel));
    }
}
var HandSkeleton = /** @class */ (function () {
    function HandSkeleton(handedness, viewWidth, viewHeight) {
        var _this = this;
        this.handedness = handedness;
        this.viewWidth = viewWidth;
        this.viewHeight = viewHeight;
        this.root = new THREE.Group();
        this.debugAxes = false;
        this.debugSpheres = true;
        this.debugSkeleton = false;
        this.debugRadius = 0.1;
        this.featureKeyToBoneMap = {}; // incrementally cache bones by bone names.
        this.fingerCurl = 0;
        this.palmFlatness = 1;
        this.handMargin = 0.5;
        this.numberOfHands = 0;
        this.rightHandedness = false;
        this.root.name = "root";
        test();
        var urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get('axes')) {
            this.debugAxes = true;
        }
        if (urlParams.get('skeleton')) {
            this.debugSkeleton = true;
        }
        var palmBaseBone = new THREE.Bone();
        palmBaseBone.name = getFeatureKey('palmBase');
        this.root.add(palmBaseBone);
        exports.FingerNames.forEach(function (fingerName, fingerIndex) {
            var jointParentBone = palmBaseBone;
            for (var jointIndex = 0; jointIndex < 4; jointIndex++) {
                var jointBone = new THREE.Bone();
                jointBone.name = getFeatureKey(fingerName, jointIndex);
                jointParentBone.add(jointBone);
                jointParentBone = jointBone;
            }
        });
        // collect bones, create lookup map and set common options
        var bones = [];
        this.root.traverse(function (object) {
            if (object.type !== 'Bone')
                return; // ignore non-bones.
            var bone = object;
            bone.matrixAutoUpdate = true;
            if (_this.debugAxes) {
                //if( bone.name == "palmBase") {
                var axesHelper = new THREE.AxesHelper(_this.debugRadius);
                bone.add(axesHelper);
                //}
            }
            bones.push(bone);
            _this.featureKeyToBoneMap[bone.name] = bone;
        });
        if (this.debugSkeleton) {
            var skeletonHelper = new THREE.SkeletonHelper(palmBaseBone);
            this.root.add(skeletonHelper);
        }
        this.skeleton = new THREE.Skeleton(bones);
    }
    HandSkeleton.prototype.getFeatureBone = function (featureKey) {
        return this.featureKeyToBoneMap[featureKey];
    };
    HandSkeleton.prototype.update = function (results) {
        var _this = this;
        var found = false;
        this.numberOfHands = 0;
        if (results.multiHandedness !== undefined) {
            this.numberOfHands = results.multiHandedness.length;
            results.multiHandedness.forEach(function (multiHandedness, index) {
                //  if( this.handedness === getHandednessFromLabel( multiHandedness.label  ) ) {
                if (!found) {
                    // console.log('results.multiHandedness', results.multiHandedness);
                    //console.log(results);
                    var multiHandLandmarks = results.multiHandLandmarks[index];
                    // console.log('multiHandLandmarks', multiHandLandmarks);
                    var worldFeatures = convertPoseArrayToWorldFeatures(multiHandLandmarks, _this.viewWidth, _this.viewHeight);
                    _this.handedness = getHandednessFromLabel(results.multiHandedness[index].label);
                    // console.log("this.handedness", _this.handedness);
                    _this.updateBonesFromWorldPositions(worldFeatures);
                    found = true;
                }
                //  }
            });
        }
        this.root.visible = found;
    };
    HandSkeleton.prototype.getGuidanceText = function () {
        var feedback = [];
        // most important last, least important first.
        if (this.fingerCurl > 0.25) {
            feedback = ["Uncurl Fingers"]; // (curl=${this.fingerCurl.toFixed(2)})`];
        }
        if (this.palmFlatness < 0.6) {
            feedback = ["Flatten Palm"]; // (flatness=${this.palmFlatness.toFixed(2)})`];
        }
        if (this.handMargin <= 0.12) {
            feedback = ["Fit Hand in View"]; // (margin=${this.handMargin.toFixed(2)})`];
        }
        if (this.numberOfHands === 0) {
            feedback = ['No Hand Found'];
        }
        if (this.numberOfHands === 2) {
            feedback = ['Too Many Hands'];
        }
        //if( this.handedness === Handedness.Right ) {
        //    feedback = ['Use Left Hand'];
        //}
        return feedback.join(', ');
    };
    HandSkeleton.prototype.updateBonesFromWorldPositions = function (worldPositions) {
        var _this = this;
        var palmToIndexFinger0 = worldPositions.indexFinger0.clone().sub(worldPositions.palmBase);
        var palmYForward = palmToIndexFinger0.clone().normalize();
        var palmXIndexToRingAcross = worldPositions.indexFinger0.clone().sub(worldPositions.ringFinger0).normalize();
        if (this.handedness === Handedness.Left) {
            palmXIndexToRingAcross.negate();
        }
        var palmZUp = palmYForward.clone().cross(palmXIndexToRingAcross);
        getZAxis(palmXIndexToRingAcross, palmYForward, palmZUp);
        // if right hand, this needs to be negated.
        var maxCurvature = 0, minCurvature = 0, curvatureSum = 0;
        exports.FingerNames.forEach(function (fingerName, fingerIndex) {
            // 4 joint positions
            var finger0 = worldPositions[getFeatureKey(fingerName, 0)];
            var finger1 = worldPositions[getFeatureKey(fingerName, 1)];
            var finger2 = worldPositions[getFeatureKey(fingerName, 2)];
            var finger3 = worldPositions[getFeatureKey(fingerName, 3)];
            // 3 digit segments
            var delta01 = finger1.clone().sub(finger0).normalize();
            var delta12 = finger2.clone().sub(finger1).normalize();
            var delta23 = finger3.clone().sub(finger2).normalize();
            // 2 joint curvatures
            var curvature012 = delta01.clone().cross(delta12).dot(palmXIndexToRingAcross);
            var curvature123 = delta12.clone().cross(delta23).dot(palmXIndexToRingAcross);
            minCurvature = Math.min(minCurvature, curvature012, curvature123);
            maxCurvature = Math.max(maxCurvature, curvature012, curvature123);
            curvatureSum += curvature012 + curvature123;
        });
        var avgCurvature = curvatureSum / 8;
        // negative curl means it is right hand.
        // if (avgCurvature > 0.0) {
        //    palmXRightAcross.negate();
        //  }
        //  const palmZUp = new THREE.Vector3();
        // getZAxis(palmXRightAcross, palmYForward, palmZUp);
        // ensure orthogonal
        getXAxis(palmYForward, palmZUp, palmXIndexToRingAcross);
        var bbox = new THREE.Box3();
        var bones = [];
        this.root.traverse(function (object) {
            if (object.type !== 'Bone')
                return; // ignore non-bones.
            bbox.expandByPoint(object.getWorldPosition(new THREE.Vector3(0, 0, 0)));
        });
        this.handMargin = 0.5 - Math.max(Math.abs(bbox.min.x), Math.abs(bbox.max.x), Math.abs(bbox.min.y), Math.abs(bbox.max.y));
        this.fingerCurl = Math.abs(avgCurvature);
        this.palmFlatness = Math.abs(palmZUp.z);
        // determine palm orientation
        var worldToPalmBasis = new THREE.Matrix4().makeBasis(palmXIndexToRingAcross, palmYForward, palmZUp);
        // console.log( 'worldToPalmBasis', worldToPalmBasis );
        var worldToPalmRotation = new THREE.Quaternion().setFromRotationMatrix(worldToPalmBasis);
        worldToPalmRotation.normalize();
        var palmToWorldBasis = worldToPalmBasis.clone().invert();
        worldToPalmBasis.makeRotationFromQuaternion(worldToPalmRotation);
        worldToPalmBasis.extractBasis(palmXIndexToRingAcross, palmYForward, palmZUp);
        // console.log( 'worldToPalmBasis basics', palmXAcross, palmYForward, palmZUp );
        var palmBase = this.getFeatureBone('palmBase');
        palmBase.quaternion.copy(worldToPalmRotation);
        palmBase.position.copy(worldPositions.palmBase);
        exports.FingerNames.forEach(function (fingerName, fingerIndex) {
            var worldJointPositions = [worldPositions.palmBase];
            for (var j = 0; j < 4; j++) {
                worldJointPositions.push(worldPositions[getFeatureKey(fingerName, j)]);
            }
            var parentToWorldBasis = palmToWorldBasis.clone();
            for (var j = 0; j < worldJointPositions.length - 1; j++) {
                var bone = _this.getFeatureBone(getFeatureKey(fingerName, j));
                if (j - 1 >= 0) {
                    bone.position.y = worldJointPositions[j].clone().sub(worldJointPositions[j - 1]).length();
                }
                else {
                    bone.position.y = 0;
                }
                var boneYDirection = new THREE.Vector3(0, 1, 0);
                if ((j + 1) < worldJointPositions.length) {
                    boneYDirection.copy(worldJointPositions[j + 1]).sub(worldJointPositions[j]).normalize();
                }
                var boneXAcross = palmXIndexToRingAcross;
                if (j > 1 && (j + 1) < worldJointPositions.length) {
                    var prevBoneYDirection = new three_1.Vector3();
                    prevBoneYDirection.copy(worldJointPositions[j]).sub(worldJointPositions[j - 1]).normalize();
                    boneXAcross = boneYDirection.clone().cross(prevBoneYDirection).normalize();
                    if (boneXAcross.dot(palmXIndexToRingAcross) < 0) {
                        boneXAcross.multiplyScalar(-1);
                    }
                }
                //const boneXAcross = palmXAcross;
                var boneZUp = new THREE.Vector3();
                getZAxis(boneXAcross, boneYDirection, boneZUp);
                getXAxis(boneYDirection, boneZUp, boneXAcross); // ensure orthogonality
                var worldToBoneBasis = new THREE.Matrix4().makeBasis(boneXAcross, boneYDirection, boneZUp);
                var parentToBoneBasis = new THREE.Matrix4().multiplyMatrices(parentToWorldBasis, worldToBoneBasis);
                parentToWorldBasis.copy(worldToBoneBasis).invert();
                bone.quaternion.setFromRotationMatrix(parentToBoneBasis);
            }
        });
    };
    HandSkeleton.prototype.updateBonesFromWorldPositionsOld = function (worldPositions) {
        var _this = this;
        var palmToIndexFinger0 = worldPositions.indexFinger0.clone().sub(worldPositions.palmBase);
        var palmYForward = palmToIndexFinger0.clone().normalize();
        var palmXIndexToRingAcross = worldPositions.indexFinger0.clone().sub(worldPositions.ringFinger0).normalize();
        if (this.handedness === Handedness.Right) {
            palmXIndexToRingAcross.negate();
        }
        var palmZUp = palmYForward.clone().cross(palmXIndexToRingAcross);
        getZAxis(palmXIndexToRingAcross, palmYForward, palmZUp);
        // if right hand, this needs to be negated.
        var maxCurvature = 0, minCurvature = 0, curvatureSum = 0;
        exports.FingerNames.forEach(function (fingerName, fingerIndex) {
            // 4 joint positions
            var finger0 = worldPositions[getFeatureKey(fingerName, 0)];
            var finger1 = worldPositions[getFeatureKey(fingerName, 1)];
            var finger2 = worldPositions[getFeatureKey(fingerName, 2)];
            var finger3 = worldPositions[getFeatureKey(fingerName, 3)];
            // 3 digit segments
            var delta01 = finger1.clone().sub(finger0).normalize();
            var delta12 = finger2.clone().sub(finger1).normalize();
            var delta23 = finger3.clone().sub(finger2).normalize();
            // 2 joint curvatures
            var curvature012 = delta01.clone().cross(delta12).dot(palmXIndexToRingAcross);
            var curvature123 = delta12.clone().cross(delta23).dot(palmXIndexToRingAcross);
            minCurvature = Math.min(minCurvature, curvature012, curvature123);
            maxCurvature = Math.max(maxCurvature, curvature012, curvature123);
            curvatureSum += curvature012 + curvature123;
        });
        var avgCurvature = curvatureSum / 8;
        // negative curl means it is right hand.
        // if (avgCurvature > 0.0) {
        //    palmXRightAcross.negate();
        //  }
        //  const palmZUp = new THREE.Vector3();
        // getZAxis(palmXRightAcross, palmYForward, palmZUp);
        // ensure orthogonal
        getXAxis(palmYForward, palmZUp, palmXIndexToRingAcross);
        var bbox = new THREE.Box3();
        var bones = [];
        this.root.traverse(function (object) {
            if (object.type !== 'Bone')
                return; // ignore non-bones.
            bbox.expandByPoint(object.getWorldPosition(new THREE.Vector3(0, 0, 0)));
        });
        this.handMargin = 0.5 - Math.max(Math.abs(bbox.min.x), Math.abs(bbox.max.x), Math.abs(bbox.min.y), Math.abs(bbox.max.y));
        this.fingerCurl = Math.abs(avgCurvature);
        this.palmFlatness = Math.abs(palmZUp.z);
        // determine palm orientation
        var worldToPalmBasis = new THREE.Matrix4().makeBasis(palmXIndexToRingAcross, palmYForward, palmZUp);
        // console.log( 'worldToPalmBasis', worldToPalmBasis );
        var worldToPalmRotation = new THREE.Quaternion().setFromRotationMatrix(worldToPalmBasis);
        worldToPalmRotation.normalize();
        var palmToWorldBasis = worldToPalmBasis.clone().invert();
        worldToPalmBasis.makeRotationFromQuaternion(worldToPalmRotation);
        worldToPalmBasis.extractBasis(palmXIndexToRingAcross, palmYForward, palmZUp);
        // console.log( 'worldToPalmBasis basics', palmXAcross, palmYForward, palmZUp );
        var palmBase = this.getFeatureBone('palmBase');
        palmBase.quaternion.copy(worldToPalmRotation);
        palmBase.position.copy(worldPositions.palmBase);
        exports.FingerNames.forEach(function (fingerName, fingerIndex) {
            var worldJointPositions = [worldPositions.palmBase];
            for (var j = 0; j < 4; j++) {
                worldJointPositions.push(worldPositions[getFeatureKey(fingerName, j)]);
            }
            var parentToWorldBasis = palmToWorldBasis.clone();
            for (var j = 0; j < worldJointPositions.length - 1; j++) {
                var bone = _this.getFeatureBone(getFeatureKey(fingerName, j));
                if (j - 1 >= 0) {
                    bone.position.y = worldJointPositions[j].clone().sub(worldJointPositions[j - 1]).length();
                }
                else {
                    bone.position.y = 0;
                }
                var boneYDirection = new THREE.Vector3(0, 1, 0);
                if ((j + 1) < worldJointPositions.length) {
                    boneYDirection.copy(worldJointPositions[j + 1]).sub(worldJointPositions[j]).normalize();
                }
                var boneXAcross = palmXIndexToRingAcross;
                if (j > 1 && (j + 1) < worldJointPositions.length) {
                    var prevBoneYDirection = new three_1.Vector3();
                    prevBoneYDirection.copy(worldJointPositions[j]).sub(worldJointPositions[j - 1]).normalize();
                    boneXAcross = boneYDirection.clone().cross(prevBoneYDirection).normalize();
                    if (boneXAcross.dot(palmXIndexToRingAcross) < 0) {
                        boneXAcross.multiplyScalar(-1);
                    }
                }
                //const boneXAcross = palmXAcross;
                var boneZUp = new THREE.Vector3();
                getZAxis(boneXAcross, boneYDirection, boneZUp);
                getXAxis(boneYDirection, boneZUp, boneXAcross); // ensure orthogonality
                var worldToBoneBasis = new THREE.Matrix4().makeBasis(boneXAcross, boneYDirection, boneZUp);
                var parentToBoneBasis = new THREE.Matrix4().multiplyMatrices(parentToWorldBasis, worldToBoneBasis);
                parentToWorldBasis.copy(worldToBoneBasis).invert();
                bone.quaternion.setFromRotationMatrix(parentToBoneBasis);
            }
        });
    };
    return HandSkeleton;
}());
exports.HandSkeleton = HandSkeleton;
