import {  useEffect } from "react";
import { Vector3, Euler, Quaternion, Bone } from "three";
import { useMemo } from "react";
import Ring from "./ring.js";
import { HandSkeleton, Handedness, getFeatureKey } from "./HandSkeleton.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { useThree } from "@react-three/fiber";
import { useFrame } from '@react-three/fiber'

const CameraController = () => {
  const { camera, gl } = useThree();
  useEffect(
    () => {
      const controls = new OrbitControls(camera, gl.domElement);
      controls.minDistance = 3;
      controls.maxDistance = 20;
      return () => {
        controls.dispose();
      };
    },
    [camera, gl]
  );
  return null;
};

const ResizeHandler = ({ aspect }) => {
  const { camera } = useThree();
  camera.left = -0.5 * aspect;
  camera.right = 0.5 * aspect;
  camera.top = 0.5;
  camera.bottom = -0.5;
  camera.updateProjectionMatrix(); 

  return null;
}

function DefaultCamera(){
  const { camera } = useThree();

  useEffect(
    () => {
      camera.position.set( 0, 0, -0.5 );
      camera.rotation.set( Math.PI, 0, Math.PI );
      camera.zoom = 1;
      camera.updateProjectionMatrix(); 
    },
    [camera]
  );
  return null;
}   


export default function RingFinger({ width, height, prediction, aspect, bandColor, gemColor, updateDisplay}) {
  let leftHandSkeleton = useMemo(() => {
    return new HandSkeleton(Handedness.Left, width, height);
  }, [width, height]);
  useFrame(() => {
    
  })

  const bone = useMemo(() => {
    if (prediction.image !== undefined) {
      leftHandSkeleton.update(prediction);
      updateDisplay();
      return leftHandSkeleton.getFeatureBone(getFeatureKey("ringFinger", 1));
    }
    return new Bone();
  }, [prediction]);
  
  let position = new Vector3();
  let rotation = new Quaternion();
  let scale = new Vector3();

  let matrixWorld = bone.matrixWorld;
  matrixWorld.decompose(position, rotation, scale);

  const ringMediaBone = leftHandSkeleton.getFeatureBone(getFeatureKey("ringFinger", 1))
  let proximalBoneLength = ringMediaBone.position.y;

  let rotationEuler = new Euler().setFromQuaternion(rotation);

  if (prediction.image && prediction.multiHandLandmarks.length) {
    return (
      <group>
        <DefaultCamera />
        <Ring gemColor={gemColor} bandColor={bandColor} position={position} rotation={rotationEuler} scale={scale} proximalBoneLength={proximalBoneLength} handModel={true} />
        <ResizeHandler aspect={aspect}/>
      </group>
    );
  }
  else{
    return(
      <group>
        <CameraController />
        <Ring gemColor={gemColor} bandColor={bandColor} position={[0, 0, 0]}  rotation={[-Math.PI/2, 0, 0]} scale={1} proximalBoneLength={1} handModel={false}/>
        <ResizeHandler aspect={aspect}/>
      </group>
    );
  }

}
