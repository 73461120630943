import circle from "./resources/cirlce.png";

import { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

import iconOne from "./resources/gold.png";
import iconTwo from "./resources/silver.png";
import iconRoseGold from "./resources/rose-gold.png";
import iconWhiteGold from "./resources/white-gold.png";
import iconPlatinum from "./resources/platinum.png";
import iconSix from "./resources/gem.png";
import iconSeven from "./resources/band.png";

// Band colors
const PINK_GOLD = { assetId: "17254639-0f89-4146-b8d4-c51aaf0d8170" };
const WHITE_GOLD = { assetId: "59849d0f-aeb6-45d9-92ec-6fed6daf052f" };
const YELLOW_GOLD = { assetId: "f31255b1-7f2e-4bf1-985b-eb424b4e843d" };
const PLATINUM = { assetId: "17bda33e-57e1-457c-95a0-d5b1b39f72ce" };
const SAPPHIRE_COLOR = "0c3ba6";
// Gemstone Colors
const RUBY_COLOR = { assetId: "68f93597-e9d1-4c7a-bb5c-a2c13fcd6da9" };
const SAPPHIRE = { assetId: "1b57b15c-6e7f-4ac4-a6a6-8d05fefc0f31" };
const EMERALD = { assetId: "31f582d6-8ee8-4c0a-bd36-c0fcd48ee8cc" };
const DIAMOND = { assetId: "85e8e466-929b-4e6d-8b1a-f0bb2b25336b" };
const TEST_COLOR = { assetId: "28c7b63d-9608-41c1-9d68-1f4429706214" };

// const RUBY_COLOR = { assetId: "28c7b63d-9608-41c1-9d68-1f4429706214" };
// const SAPPHIRE = { assetId: "28c7b63d-9608-41c1-9d68-1f4429706214" };
// const EMERALD = { assetId: "28c7b63d-9608-41c1-9d68-1f4429706214" };
// const DIAMOND = { assetId: "28c7b63d-9608-41c1-9d68-1f4429706214" };

// export default function Scroller ({ setGemColor, setBandColor }) {
//   const [swiperIndex, setSwiperIndex] = useState(1);
//   const [swiper, setSwiper] = useState();

//   function handleSlideChange (swiper) {
//     setSwiperIndex(swiper.activeIndex);
//   }

//   useEffect(() => {
//     if (swiperIndex === 0) {
//       setBandColor(PINK_GOLD);
//     } else if (swiperIndex === 1) {
//       setBandColor(WHITE_GOLD);
//     } else if (swiperIndex === 2) {
//       setBandColor(YELLOW_GOLD);
//     } else if (swiperIndex === 3) {
//       setBandColor(PLATINUM);
//     }
//   }, [swiperIndex]);

//   function initSwiper (swiper) {
//     swiper.slideTo(1, 0);
//   }
//   return (
//     <div>
//       <Swiper
//         className={"swiper-main"}
//         spaceBetween={0}
//         centeredSlides={true}
//         slidesPerView={7}
//         onSlideChange={swiper => handleSlideChange(swiper)}
//         onSwiper={swiper => {
//           initSwiper(swiper);
//           setSwiper(swiper);
//         }}>
//         {/* Gem */}
//         <SwiperSlide onClick={() => swiper.slideTo(0, 300)} id={0}>
//           <img className='icon' style={{ transform: `translate(-50%) ${swiperIndex === 0 ? "scale(0.75)" : "scale(0.5)"}` }} src={iconSix} />
//         </SwiperSlide>
//         {/* Band */}
//         <SwiperSlide onClick={() => swiper.slideTo(1, 300)} id={1}>
//           <img className='icon' style={{ transform: `translate(-50%) ${swiperIndex === 1 ? "scale(0.75)" : "scale(0.5)"}` }} src={iconSeven} />
//         </SwiperSlide>
//       </Swiper>
//       <Swiper
//         style={{
//           zIndex: "100",
//           maxWidth: "806px",
//           width: "110vw",
//           position: "absolute",
//           bottom: "100px",
//           marginLeft: "50%",
//           transform: `translateX(calc(-50% - 17px))`,
//           zIndex: "1001",
//         }}
//         spaceBetween={0}
//         centeredSlides={true}
//         slidesPerView={7}
//         onSlideChange={swiper => handleSlideChange(swiper)}
//         onSwiper={swiper => {
//           initSwiper(swiper);
//           setSwiper(swiper);
//         }}>
//         <SwiperSlide onClick={() => swiper.slideTo(0, 300)} id={0}>
//           <img
//             style={{ position: "relative", left: "50%", top: "50%", transform: `translate(-50%) ${swiperIndex === 0 ? "scale(0.75)" : "scale(0.5)"}` }}
//             src={iconRoseGold}
//           />
//         </SwiperSlide>
//         <SwiperSlide onClick={() => swiper.slideTo(1, 300)} id={1}>
//           <img
//             style={{ position: "relative", left: "50%", top: "50%", transform: `translate(-50%) ${swiperIndex === 1 ? "scale(0.75)" : "scale(0.5)"}` }}
//             src={iconWhiteGold}
//           />
//         </SwiperSlide>
//         <SwiperSlide onClick={() => swiper.slideTo(2, 300)} id={2}>
//           <img
//             style={{ position: "relative", left: "50%", top: "50%", transform: `translate(-50%) ${swiperIndex === 1 ? "scale(0.75)" : "scale(0.5)"}` }}
//             src={iconOne}
//           />
//         </SwiperSlide>{" "}
//         <SwiperSlide onClick={() => swiper.slideTo(3, 300)} id={3}>
//           <img
//             style={{ position: "relative", left: "50%", top: "50%", transform: `translate(-50%) ${swiperIndex === 1 ? "scale(0.75)" : "scale(0.5)"}` }}
//             src={iconPlatinum}
//           />
//         </SwiperSlide>
//       </Swiper>
//       <img style={{ position: "absolute", marginLeft: "50%", transform: "translateX(-50%)", bottom: "15px", height: "90px", zIndex: "1000" }} src={circle} />
//     </div>
//   );
// }

export default function Scroller ({ setGemColor, setBandColor }) {
  const [swiperIndex, setSwiperIndex] = useState(0);
  const [swiper, setSwiper] = useState();
  const [gemSwiper, setGemSwiper] = useState();
  const [bandSwiper, setBandSwiper] = useState();
  const [gemIndex, setGemIndex] = useState(1);
  const [bandIndex, setBandIndex] = useState(0);

  function handleSlideChange (swiper) {
    setSwiperIndex(swiper.activeIndex);
  }

  function initSwiper (swiper) {
    swiper.slideTo(0, 0);
  }

  useEffect(() => {
    if (gemIndex === 0) {
      setGemColor(DIAMOND);
    } else if (gemIndex === 1) {
      setGemColor(RUBY_COLOR);
    } else if (gemIndex === 2) {
      setGemColor(SAPPHIRE);
    } else if (gemIndex === 3) {
      setGemColor(EMERALD);
    } else if (gemIndex === 4) {
    setGemColor(TEST_COLOR);
  }
  }, [gemIndex]);

  useEffect(() => {
    if (bandIndex === 0) {
      setBandColor(WHITE_GOLD);
    } else if (bandIndex === 1) {
      setBandColor(PINK_GOLD);
    } else if (bandIndex === 2) {
      setBandColor(YELLOW_GOLD);
    } else if (bandIndex === 3) {
      setBandColor(PLATINUM);
    }
  }, [bandIndex]);

  return (
    <div>
      <Swiper
        className={"swiper-main"}
        spaceBetween={0}
        centeredSlides={true}
        slidesPerView={7}
        onSlideChange={swiper => handleSlideChange(swiper)}
        onSwiper={swiper => {
          initSwiper(swiper);
          setSwiper(swiper);
        }}>
        <SwiperSlide onClick={() => swiper.slideTo(0, 300)} id={0}>
          <img className='icon' style={{ transform: `translate(-50%) ${swiperIndex === 0 ? "scale(0.75)" : "scale(0.5)"}` }} src={iconSix} />
        </SwiperSlide>

        <SwiperSlide onClick={() => swiper.slideTo(1, 300)} id={1}>
          <img className='icon' style={{ transform: `translate(-50%) ${swiperIndex === 1 ? "scale(0.75)" : "scale(0.5)"}` }} src={iconSeven} />
        </SwiperSlide>
      </Swiper>

      <img className='center-circle' style={{ bottom: "15px" }} src={circle} />
      <img className='center-circle' style={{ bottom: "115px" }} src={circle} />

      {swiperIndex === 0 && (
        <Swiper
          className={"swiper-main"}
          style={{ bottom: "100px" }}
          spaceBetween={0}
          centeredSlides={true}
          slidesPerView={7}
          onSlideChange={swiper => setGemIndex(swiper.activeIndex)}
          onSwiper={swiper => {
            swiper.slideTo(gemIndex, 0);
            setGemSwiper(swiper);
          }}>
          {/* Diamond */}
          <SwiperSlide onClick={() => gemSwiper.slideTo(0, 300)} id={1}>
            <img className='icon' style={{ transform: `translate(-50%) ${gemIndex === 0 ? "scale(0.75)" : "scale(0.5)"}` }} src={iconTwo} />
          </SwiperSlide>
          {/* Ruby */}
          <SwiperSlide onClick={() => gemSwiper.slideTo(1, 300)} id={1}>
            <img className='icon' style={{ transform: `translate(-50%) ${gemIndex === 1 ? "scale(0.75)" : "scale(0.5)"}` }} src={iconTwo} />
          </SwiperSlide>
          {/* Sapphire */}
          <SwiperSlide onClick={() => gemSwiper.slideTo(2, 300)} id={1}>
            <img className='icon' style={{ transform: `translate(-50%) ${gemIndex === 2 ? "scale(0.75)" : "scale(0.5)"}` }} src={iconTwo} />
          </SwiperSlide>
          {/* Emerald */}
          <SwiperSlide onClick={() => gemSwiper.slideTo(2, 300)} id={1}>
            <img className='icon' style={{ transform: `translate(-50%) ${gemIndex === 2 ? "scale(0.75)" : "scale(0.5)"}` }} src={iconTwo} />
          </SwiperSlide>
          {/* Test */}
          <SwiperSlide onClick={() => gemSwiper.slideTo(2, 300)} id={1}>
            <img className='icon' style={{ transform: `translate(-50%) ${gemIndex === 2 ? "scale(0.75)" : "scale(0.5)"}` }} src={iconTwo} />
          </SwiperSlide>
        </Swiper>
      )}

      {swiperIndex === 1 && (
        <Swiper
          className={"swiper-main"}
          style={{ bottom: "100px" }}
          spaceBetween={0}
          centeredSlides={true}
          slidesPerView={7}
          onSlideChange={swiper => setBandIndex(swiper.activeIndex)}
          onSwiper={swiper => {
            swiper.slideTo(bandIndex, 0);
            setBandSwiper(swiper);
          }}>
          {/* White Gold */}
          <SwiperSlide onClick={() => bandSwiper.slideTo(0, 300)} id={1}>
            <img className='icon' style={{ transform: `translate(-50%) ${bandIndex === 0 ? "scale(0.75)" : "scale(0.5)"}` }} src={iconWhiteGold} />
          </SwiperSlide>
          {/* Pink Gold`` */}
          <SwiperSlide onClick={() => bandSwiper.slideTo(1, 300)} id={1}>
            <img className='icon' style={{ transform: `translate(-50%) ${bandIndex === 1 ? "scale(0.75)" : "scale(0.5)"}` }} src={iconRoseGold} />
          </SwiperSlide>
          {/* Yellow Gold */}
          <SwiperSlide onClick={() => bandSwiper.slideTo(1, 300)} id={1}>
            <img className='icon' style={{ transform: `translate(-50%) ${bandIndex === 1 ? "scale(0.75)" : "scale(0.5)"}` }} src={iconOne} />
          </SwiperSlide>
          {/* Platinum */}
          <SwiperSlide onClick={() => bandSwiper.slideTo(1, 300)} id={1}>
            <img className='icon' style={{ transform: `translate(-50%) ${bandIndex === 1 ? "scale(0.75)" : "scale(0.5)"}` }} src={iconPlatinum} />
          </SwiperSlide>
        </Swiper>
      )}
    </div>
  );
}
