import { useState, useEffect, useRef, Suspense, useMemo } from "react";
import "./styles.css";
import Scroller from "./Scoller.js";

import "@mediapipe/hands";
import logo from "./resources/logo.png";
import * as MediaPipeHands from "@mediapipe/hands";
import RingFinger from "./RingFinger";
import TryOnCanvas from "./TryOnCanvas";

import { Canvas } from "@react-three/fiber";
import { Environment } from "@react-three/drei";

console.log( "build 2022-05-16 - 1");

export default function App() {
  const [splashScreen, setSplashScreen] = useState(true);
  const [prediction, setPrediction] = useState({});
  const [gemColor, setGemColor] = useState("00ff00");
  const [bandColor, setBandColor] = useState({assetId: '17254639-0f89-4146-b8d4-c51aaf0d8170'});
  const tryOnCanvasRef = useRef();

  let videoWidth = 1280,
    videoHeight = 720;
  const videoAspect = videoWidth / videoHeight;
  let isMirrored = true;
  const urlParams = new URLSearchParams(window.location.search);

  if (urlParams.get("mirror") === "false") {
    isMirrored = false;
  }

  let hands = useMemo(() => {
    const hands = new MediaPipeHands.Hands({
      locateFile: (file) => `https://cdn.jsdelivr.net/npm/@mediapipe/hands@0.3.1632795355/${file}`,
    });
    hands.setOptions({ minDetectionConfidence: 0.7, minTrackingConfidence: 0.7 });
    hands.onResults(setPrediction);
    return hands;
  }, ["hands"]);

  function onImageReady(croppedCanvas) {
    hands.send({ image: croppedCanvas });
  }

  useEffect(() => {

    if (prediction.image !== undefined) {
      if (splashScreen) {
        setSplashScreen(false);
      }
      setTimeout(() => {
        tryOnCanvasRef.current.sendNextImage();
      }, 10);
    }
  }, [prediction]);

  return (
    <div className="App">
      {splashScreen && (
        <div className="Outer">
          <img className="Preview_image" src={logo} alt="" />
        </div>
      )}
      {!splashScreen && <Scroller setGemColor={setGemColor} setBandColor={setBandColor} />}
      <TryOnCanvas
        ref={tryOnCanvasRef}
        onImageReady={onImageReady}
        videoWidth={videoWidth}
        videoHeight={videoHeight}
        prediction={prediction}
        isMirrored={isMirrored}
      />

      <div style={{ position: "absolute", height: "100%", width: "100%", top: "0px" }}>
        <div
          style={{
            marginLeft: "50%",
            width: `${(window.innerHeight * videoWidth) / videoHeight}px`,
            height: `${window.innerHeight}px`,
            transform: "translateX(-50%)",
          }}>
          <Canvas
            onCreated={({ camera }) => {
              camera.left = -0.5 * videoAspect * (isMirrored ? -1 : 1);
              camera.right = 0.5 * videoAspect * (isMirrored ? -1 : 1);
              camera.top = 0.5;
              camera.bottom = -0.5;
              camera.lookAt(0, 0, 1);
              camera.updateProjectionMatrix();
            }}
            orthographic
            camera={{
              near: -300,
              far: 300,
              position: [0, 0, -0.5],
            }}>
            <Suspense fallback={null}>
              {/* <Environment background={false} files={"warehouse.hdr"} path="./resources/" /> */}
              <Environment background={false} files={"ColumnsStudio_latlong_V2_1k.hdr"} path="https://solutions-engineering.s3.amazonaws.com/" />

              {/* <ambientLight intensity={0.3} /> */}
              {!splashScreen && (
                <RingFinger
                  updateDisplay={() => tryOnCanvasRef.current.updateDisplay()}
                  gemColor={gemColor}
                  bandColor={bandColor}
                  prediction={prediction}
                  aspect={(isMirrored ? -1 : 1) * videoAspect}
                  width={videoWidth}
                  height={videoHeight}
                />
              )}
            </Suspense>
          </Canvas>
        </div>
      </div>
    </div>
  );
}
