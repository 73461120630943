import { forwardRef, useRef, useImperativeHandle, useEffect } from "react";
import Webcam from "react-webcam";

const TryOnCanvas = forwardRef(({ onImageReady, videoWidth, videoHeight, prediction, isMirrored }, ref) => {
  const webcamRef = useRef();
  const offScreenCanvasRef = useRef();
  const displayCanvasRef = useRef();
  const croppedCanvasRef = useRef();
  const videoAspect = videoWidth / videoHeight;

  const videoConstraints = {
    width: videoWidth,
    height: videoHeight,
    facingMode: "user",
  };

  useImperativeHandle(ref, () => ({
    sendNextImage: () => {
      sendNextImage();
    },
    updateDisplay: () => {
      updateDisplay();
    },
  }));

  const updateDisplay = () => {
    if (prediction.image !== undefined) {
      const offscreenCanvas = offScreenCanvasRef.current;
      const displayCtx = displayCanvasRef.current.getContext("2d");
      if (offscreenCanvas.height !== 0) {
        displayCtx.drawImage(offscreenCanvas, 0, 0, videoWidth, videoHeight);
      }
    }
  };

  const sendNextImage = () => {
    let video = webcamRef.current.video;

    const offscreenCanvas = offScreenCanvasRef.current;
    const offScreenCtx = offscreenCanvas.getContext("2d");

    const croppedCanvas = croppedCanvasRef.current;
    const croppedCanvasCtx = croppedCanvas.getContext("2d");

    const width = croppedCanvas.height * videoAspect;

    const left = (croppedCanvas.width - width) * 0.5;
    const top = 0;
    const height = croppedCanvas.height;
    croppedCanvasCtx.drawImage(video, left, top, width, height);

    offScreenCtx.drawImage(video, 0, 0);
    onImageReady(croppedCanvas);
  };

  return (
    <div>
      <Webcam
      id="webcam"
        style={{ zIndex:'10',position: "absolute", height: "1px", width: "1px", top: "50%", left: "50%" }}
        ref={webcamRef}
        onUserMedia={() => {
          sendNextImage();
        }}
        videoConstraints={videoConstraints}
      />
      <div style={{ display: "none" }}>
        <canvas ref={offScreenCanvasRef} width={videoWidth} height={videoHeight} />
      </div>
      <div style={{ display: "none" }}>
        <canvas ref={croppedCanvasRef} width={videoHeight} height={videoHeight} />
      </div>
      <div className="OuterDiv" style={{ transform: `scaleX(${isMirrored ? -1 : 1})`, zIndex: "-101" }}>
        <canvas className="Canvas" ref={displayCanvasRef} width={videoWidth} height={videoHeight} />
      </div>
    </div>
  );
});

export default TryOnCanvas;
