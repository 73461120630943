import { Suspense } from "react";
import { Asset, ThreekitSource } from "@threekit/lignin";

const RING_POSITION = 0.2;
const RING_SCALE = 0.1;

export default function Ring ({ position, rotation, scale, proximalBoneLength, handModel, gemColor, bandColor }) {
  return (
    <ThreekitSource env='preview' org='lv-ring-vto' token='040f1dac-26a2-457c-8d9d-a8356d66aa87'>
      <group scale={scale} position={position} rotation={rotation}>
        <group scale={RING_SCALE * proximalBoneLength} position={[0, proximalBoneLength * RING_POSITION * handModel, 0]}>
          <Suspense fallback={null}>
            <Asset id={"e6e49425-96b4-44ec-90a1-25a8c6ee91cf"} configuration={{ 'Gemstone': gemColor, 'Metal Material': bandColor }} />

            {/* {handModel && (
              <mesh position={[0, 0, 0]}>
                <cylinderBufferGeometry attach='geometry' args={[1, 1, 2, 50, 1]} />
                <meshBasicMaterial attach='material' color='red' opacity='0' depthWrite='true' transparent='true'/>
              </mesh>
            )} */}
          </Suspense>
        </group>
      </group>
    </ThreekitSource>
  );
}
